<template>
  <div class="content">
    <!-- 内容 -->
    <template v-for="(item, i) in data">
      <div :key="i" class="contentArr">
        <div class="contentText">
          <div class="gang"></div>
          <h3>{{ item.title }}</h3>
          <p v-html="item.text"></p>
          <img :src="item.imgSrc" alt="" class="img1" v-if="item.state" />
          <video :src="item.imgSrc" alt="" class="img1" controls v-else />
        </div>
      </div>
    </template>

    <!-- 内容 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [
        {
          state: true,
          imgSrc: require("@/assets/img/case1.png"),
          title: "腾讯•游云南",
          text: "秀圆果公司与腾讯在2019年建立战略合作，推出带有NFC的普洱茶及其它文创产品。“唱片茶”就是其一。<br>手机贴近普洱茶饼，原生态的云南民歌悠扬响起，茶饼的“前世今生”跃然屏上。<br>这款普洱生茶为什么会“唱歌”呢？这款茶的每一片茶饼都嵌入了NFC芯片，每个芯片都有全球唯一的识别号码，也使这片茶饼有了独一无二的“身份ID”。通过NFC溯源技术，即可查看这饼茶的“前世今生”。同样，云南原生态民歌的信息也是保存在茶饼的芯片中。<br>“以前买普洱茶总怕买贵了，或者买到假货，因为不太懂。这个NFC溯源功能挺好的，让我这样不太会辨别普洱茶的人也可以方便、可靠的了解这款茶。希望这个功能快速普及！”来自河北的参展商张先生在体验这款唱片茶后谈到。他说，NFC芯片为普洱茶的长期珍藏、验真溯源提供了有效保障。",
        },
        {
          state: false,
          // imgSrc: require("@/assets/img/斗记普洱茶.mp4"),
          imgSrc: "https://jsxygkj.oss-cn-hangzhou.aliyuncs.com/official_site/斗记普洱茶.mp4",
          title: "斗记普洱茶",
          text: "斗记是普洱茶行业中的技术领先高端品牌，是“中国普洱茶标杆品牌”、“全国十大热门普洱茶品牌”。秀圆果公司与斗记普洱茶在2018年即建立了合作，秀圆果公司为其量身定制了全国首家NFC电子内飞及防伪溯源系统，为其带来了显著的防伪溯源价值，以及品牌推广效益。随后陆续使用了秀圆果公司“溯享平台”其它软件产品，涉及生产、仓储、订货、渠道管理、门店管理多个方面。溯享平台为斗记普洱茶从传统管理模式向数字化转型提供了典范。",
        },
        {
          state: false,
          // imgSrc: require("@/assets/img/则道.mp4"),
          imgSrc: "https://jsxygkj.oss-cn-hangzhou.aliyuncs.com/official_site/则道.mp4",
          title: "则道茶业",
          text: "云南则道茶叶是普洱茶现代化、科学化、高端化的标杆，已经成为普洱茶行业内实现从树头到口头全程可追溯的企业。<br>秀圆果公司与则道茶叶在2019年达成合作，则道茶业的NFC防伪溯源、产品包装及渠道管理由秀圆果公司提供解决方案和信息系统。<br>NFC防伪溯源系统为每一饼茶赋予一个身份编号，向上可追溯到茶产地，向下可关联到发货、销售记录，为则道全程可追溯提供了物联网技术平台。",
        },
        {
          state: true,
          imgSrc: require("@/assets/img/case2.png"),
          title: "定制茶",
          text: "为客户提供茶产品NFC定制服务，手机NFC靠一靠，即可展示定制的品牌、文化宣传的图文视频，为企业和团体活动、礼品量身打造。<br>“有内涵”的普洱茶：文字、图片、视频内置普洱茶电子内飞中，为团体、企业等主体提供专属内容定制<br>“有记忆”的普洱茶：人生中的重大事情可以通过视频照片语音的形式进行数年跟踪记录，化身私人宝藏普洱茶<br>“会告白”的普洱茶：送爱人，送亲人，可以录一段深情告白上传至定制茶饼，化身幸运普洱茶<br>“会唱歌”的普洱茶：送歌迷，送粉丝，作为艺家家名片普洱茶<br>“会跳舞”的普洱茶：将艺术家影像、动画资料上传至定制茶饼，化身灵动普洱茶",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.content {
  // 偶数列背景颜色
  .contentArr:nth-child(even) {
    background-color: #f4f4f4;
  }
}
@media screen and (max-width: 750px) {
  .content {
    // 内容样式
    .contentArr {
      background: #fff;
      width: 100%;
      overflow: hidden;
      .contentText {
        padding-bottom: 2.8rem;
        width: 15.87rem;
        margin: auto;
        margin-top: 1.6rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
        h3 {
          font-size: 0.92rem;
          font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
          font-weight: bold;
          color: #093c7f;
          margin-top: 0.1rem;
        }
        p {
          // text-indent: 0.48rem;
          font-size: 0.72rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #505050;
          line-height: 1.02rem;
        }
        .img1 {
          width: 15.87rem;
          height: 10.09rem;
          margin-top: 0.46rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .content {
    // 内容样式
    .contentArr {
      background: #fff;
      width: 19.2rem;
      overflow: hidden;
      .contentText {
        width: 100%;
        float: left;
        position: relative;
        padding-bottom: 2.8rem;
        margin: 1.6rem 0 0 1.69rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
        h3 {
          height: 0.84rem;
          font-size: 0.5rem;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #093c7f;
          line-height: 0.7rem;
          margin-top: 0.05rem;
        }
        p {
          // text-indent: 0.48rem;
          margin-top: 0.2rem;
          width: 8.71rem;
          font-size: 0.2rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #000000;
          line-height: 0.36rem;
        }
        .img1 {
          width: 6.78rem;
          height: 4.32rem;
          position: absolute;
          top: 0.47rem;
          left: 9rem;
        }
        .care {
          height: 0.25rem;
          font-size: 0.18rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #093c7f;
          line-height: 0.21rem;
          margin-top: 0.2rem;
          .careContent {
            margin-bottom: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
